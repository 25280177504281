import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
    Box,
    Flex,
    Center,
    Heading
} from '@chakra-ui/react';

import ListingCard from './ListingCard';

const listings = [
  { cat: 'resorts/hotel' },
  { cat: 'motel' },
  { cat: 'bed & breakfast' },
  { cat: 'short term rental' },
  { cat: 'lodge'},
  { cat: 'pool/hot tub'},
  { cat: 'wifi'},
  { cat: 'pets allowed'},
  { cat: 'breakfast'},
  { cat: 'fitness'},
  { cat: 'rvs & camping' },
  { cat: 'groceries'}
]

const StayListingFilter = ({ location }) => {
    const [listing, setListing] = useState([]);
    const [filtered, setFiltered] = useState([]);

    const data = useStaticQuery(graphql`
    {
      allPrismicStayListing(sort: {fields: data___listing_name, order: ASC}) {
        edges {
          node {
            data {
              listing_website {
                url
                target
              }
              listing_tags {
                eat_listing_tags
              }
              listing_street
              listing_phone
              listing_description {
                text
              }
              listing_city
              listing_address_2
              listing_name
              listing_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      transformOptions: {cropFocus: CENTER}
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                    )
                  }
                  publicURL
                }
              }
              google_map_link {
                url
              }
              featured_listing
              premium_listing
            }
          }
        }
      }
    }
  `)

  const prismicData = data.allPrismicStayListing.edges;

  const stayListing = location.state?.eatListingTag;

  const handleChange = e => {
      if (e.target.checked) {
          setListing([...listing, e.target.value]);
          console.log([...listing]);
      } else {
          setListing(listing.filter(id => id !== e.target.value));
      }
  };
  // Get state from link
  useEffect(() => {
    if (stayListing === 'resorts/hotel') {
      setListing(['resorts/hotel']);
    } else if (stayListing === 'b&b') {
      setListing(['b&b']);
    }
    else if (stayListing === 'motel') {
      setListing(['motel']);
    }
    else if (stayListing === 'short term rental') {
      setListing(['short term rental']);
    }
    else if (stayListing === 'rvs & camping') {
      setListing(['rvs & camping']);
    }
    else if (stayListing === 'lodge') {
      setListing(['lodge']);
    }
    else if (stayListing === 'pool/hot tub') {
      setListing(['pool/hot tub']);
    }
    else if (stayListing === 'wifi') {
      setListing(['wifi']);
    }
    else if (stayListing === 'pets allowed') {
      setListing(['pets allowed']);
    }
    else if (stayListing === 'breakfast') {
      setListing(['breakfast']);
    }
    else if (stayListing === 'fitness') {
      setListing(['fitness']);
    }
    else if (stayListing === 'groceries') {
      setListing(['groceries']);
    }
     else {
      setListing([]);
    }
  }, [stayListing]);
  
 
  // Filter listings
  useEffect(() => { 
    if (listing.length === 0) {
        setFiltered(prismicData);
    } 
    else {
        setFiltered(
            prismicData.filter((item) =>
                listing.some((category) => (item.node.data.listing_tags).map(({eat_listing_tags}) => eat_listing_tags).flat().includes(category))
            )
        );
    }
    }, [listing, prismicData]);
    
    return (
        <>
          <Box my="8" px="4">
            <Heading as="h3" fontSize="1.5rem" mb="2">Filter listings by category: </Heading>
              <ul className="ks-cboxtags">
                {listings.map((listings, index) => (
                        <Box key={index} p="1" display="inline-block" textAlign="center">
                           <li>
                              <input
                                  id={listings.cat}
                                  type="checkbox"
                                  onChange={handleChange}
                                  value={listings.cat}
                                  checked={listing.includes(listings.cat)}
                              />
                              <label htmlFor={listings.cat}>{listings.cat}</label>
                           </li>
                        </Box>
                  )
                )}
              </ul>
          </Box>
        
              
            {filtered.length > 0 ? (
             <Flex w="100%" flexWrap="wrap">
             {filtered.map((item, idx) => {
             return (
                   <ListingCard
                       key={idx}
                       city={item.node.data.listing_city}
                       listingPhone={item.node.data.listing_phone}
                       googleMapLink={item.node.data.google_map_link.url}
                       listingStreet={item.node.data.listing_street}
                       listingAddress2={item.node.data.listing_address_2}
                       listingCity={item.node.data.listing_city}
                       mapLink={item.node.data.google_map_link.url}
                       listingWebsite={item.node.data.listing_website.url}
                       listingDescription={item.node.data.listing_description.text}
                       listingName={item.node.data.listing_name}
                       listingImage={item.node.data.listing_image.localFile}
                       listingTags={item.node.data.listing_tags}
                       premiumListing={item.node.data.premium_listing}
                       featuredListing={item.node.data.featured_listing}
                   />
                 
             )}
             )}
         </Flex>

          ) : (
            <Box>
              <Heading as="h3" fontSize="1.5rem" mb="12" textAlign="center">No listings found for this category.</Heading>
            </Box>
            )}
        </>
    )
}

export default StayListingFilter;